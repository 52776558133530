/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://uh4sbod5dzd4loi3mejloavolq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-mobpblruxjcjvpueixftmndfk4",
    "aws_cognito_identity_pool_id": "eu-west-1:1fc7800b-3b23-4e9b-950d-0c8388d756b9",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_EcgezS1LF",
    "aws_user_pools_web_client_id": "5chcprqli5osj1l4sf237q1ak6",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "pmmwebapp-20220725120421-hostingbucket-devone",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d1b3rhuqqast0r.cloudfront.net"
};


export default awsmobile;
